import InvolveAi from './InvolveAi';
function App() {
    return (
        <div className='App'>
            <InvolveAi />
        </div>
    );
}

export default App;
