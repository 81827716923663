const Home = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-3xl font-bold mb-4">Welcome to R2D2 prototype</h1>
      <p className="text-lg">behnams71@gmail.com</p>
    </div>
  );
};

export default Home;
